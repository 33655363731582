<template>
  <div>
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1"
      variant="primary"
      @click="$router.push({ path: `/credor/view/${credor.id}` }).catch(() => {})"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">Voltar</span>
    </b-button>
    <b-card>
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="avatar"
            :text="avatarText(credor.nome)"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ credor.nome }}
        </h4>
        <div class="d-flex flex-wrap">
          <b-button
            variant="outline-secondary"
            class="ml-1"
          >
            <span class="d-none d-sm-inline">Remover</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </b-media>
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="nome"
              label-for="nome"
            >
              <b-form-input
                id="nome"
                v-model="credor.nome"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Razão Social"
              label-for="razao-social"
            >
              <b-form-input
                id="full-name"
                v-model="credor.razao_social"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="cnpj"
              label-for="cnpj"
            >
              <b-form-input
                id="cnpj"
                v-model="credor.cnpj"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Inscrição Estadual"
              label-for="inscricao-estadual"
            >
              <b-form-input
                id="inscricao-estadual"
                v-model="credor.inscricao_estadual"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="credor.email"
                type="email"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="telefone"
              label-for="telefone"
            >
              <b-form-input
                id="telefone"
                v-model="credor.telefone"
                type="email"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Status"
              label-for="user-status"
            >
              <v-select
                v-model="status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="status-selecionado"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Cidade"
              label-for="cidade"
            >
              <v-select
                v-model="cid"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cids"
                :reduce="val => val.value"
                :clearable="false"
                input-id="cidade-selecionada"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Endereco"
              label-for="endereco"
            >
              <b-form-input
                id="company"
                v-model="credor.endereco"
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>
      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="atualizarCredor"
      >
        Atualizar Credor
      </b-button>
    </b-card>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BCard,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import axios from '../../../../axios-auth'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    return {
      avatarText,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
    }
  },
  data() {
    return {
      credor: null,
      id: 1,
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/10-small.png'),
      nome: 'GRUPOVOZ',
      cnpj: '00.000.000/0000-1',
      email: 'kocrevy0@thetimes.co.uk',
      city: 'Krasnosilka',
      age: '61',
      cid: null,
      status: null,
      experience: '1 Year',
      cids: [],
      statusOptions: [
        { label: 'Ativo', value: 'true' },
        { label: 'Inativo', value: 'false' },
      ],
    }
  },
  created() {
    axios.get(`api/v1/credores/edit/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        const dadosCidade = res.data.mensagem.cidades
        const Cidades = []
        this.credor = res.data.mensagem.credores
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const prop in dadosCidade) {
          Cidades.push({
            label: dadosCidade[prop].nome,
            value: dadosCidade.id,
          })
        }
        this.cids = Cidades
      })
  },
  methods: {
    atualizarCredor() {
      this.credor.status = this.status
      axios.put(`api/v1/credores/update/${this.$route.params.id}`, this.credor, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.$swal({
            title: res.data.mensagem,
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
